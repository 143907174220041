<Page title="{TRANSLATED.PAGE_TITLE}" pageid="define-scope">
  <p>
    {@html TRANSLATED.INTRODUCTION}
  </p>
  <ResourceLink href="https://www.w3.org/TR/WCAG-EM/#step1">{TRANSLATED.RESOURCE_LINK_NAME}</ResourceLink>

  <form id="defineScopeForm" method="" novalidate>
    <Input
      id="site_name"
      label="{TRANSLATED.SITE_NAME_LABEL}"
      helptext="{TRANSLATED.SITE_NAME_HELPTEXT}"
      bind:value="{$scopeStore['SITE_NAME']}"
      deutsch
    />

    <!-- FFG: additional evaluation fields -->
    <Select
      id="app_type"
      label="{TRANSLATED.FFG_APP_TYPE_LABEL} {TRANSLATED.FFG_REQUIRED}"
      helptext="{TRANSLATED.FFG_APP_TYPE_HELPTEXT}"
      options="{appTypes}"
      value="{$scopeStore['APP_TYPE']}"
      on:change={updateAppType}
    />

    <Select
      id="report_type"
      label="{TRANSLATED.FFG_REPORT_TYPE_LABEL} {TRANSLATED.FFG_REQUIRED}"
      helptext="{TRANSLATED.FFG_REPORT_TYPE_HELPTEXT}"
      options="{reportTypes}"
      value="{$scopeStore['REPORT_TYPE']}"
      on:change={updateReportType}
    />
  
    <Input
      id="statement_url"
      label="{TRANSLATED.FFG_STATEMENT_URL_LABEL}"
      helptext="{TRANSLATED.FFG_STATEMENT_URL_HELPTEXT}"
      bind:value="{$scopeStore['STATEMENT_URL']}"
    />

    <Select
      id="statement_compliance"
      label="{TRANSLATED.FFG_STATEMENT_COMPLIANCE_LABEL} {TRANSLATED.FFG_REQUIRED}"
      helptext="{TRANSLATED.FFG_STATEMENT_COMPLIANCE_HELPTEXT}"
      options="{statementComplianceTypes}"
      value="{$scopeStore['STATEMENT_COMPLIANCE']}"
      on:change={updateStatementCompliance}
    />

    <Textarea
      id="website_scope"
      label="{TRANSLATED.SITE_SCOPE_LABEL}"
      helptext="{`
        <p>${TRANSLATED.SITE_SCOPE_HELPTEXT_P1}</p>
        <ul>
          <li>${TRANSLATED.SITE_SCOPE_HELPTEXT_LI1}</li>
          <li>${TRANSLATED.SITE_SCOPE_HELPTEXT_LI2}</li>
          <li>${TRANSLATED.SITE_SCOPE_HELPTEXT_LI3}</li>
        </ul>
      `}"
      bind:value="{$scopeStore['WEBSITE_SCOPE']}"
      deutsch
    />

    <!-- FFG: add note regarding EN 301 549 standard -->
    <p>
      {TRANSLATED.FFG_WCAG_VERSION_NOTE}
    </p>

    <!-- FFG: disable wcag-version and conformance-target fields -->
    <Select
      disabled
      id="wcag_version"
      label="{TRANSLATED.WCAG_VERSION_LABEL}"
      helptext="{TRANSLATED.WCAG_VERSION_HELPTEXT}"
      options="{wcagVersions}"
      value="{$scopeStore['WCAG_VERSION']}"
      on:change={updateWCAGversion}
    />

    <Select
      disabled
      id="conformance_target"
      label="{TRANSLATED.CONFORMANCE_TARGET_LABEL}"
      helptext="{TRANSLATED.CONFORMANCE_TARGET_HELPTEXT}"
      options="{conformanceLevels}"
      value="{$scopeStore['CONFORMANCE_TARGET']}"
      on:change={updateConformanceTarget}
    />

    <Textarea
      id="as_baseline"
      label="{TRANSLATED.ACCESSIBILITY_SUPPORT_BASELINE_LABEL}"
      helptext="{TRANSLATED.ACCESSIBILITY_SUPPORT_BASELINE_HELPTEXT}"
      bind:value="{$scopeStore['AS_BASELINE']}"
      deutsch
    />

    <Textarea
      id="additional_requirements"
      label="{TRANSLATED.ADDITIONAL_REQUIREMENTS_LABEL}"
      helptext="{`
        <p>${TRANSLATED.ADDITIONAL_REQUIREMENTS_HELPTEXT_P1}</p>
        <ul>
          <li>${TRANSLATED.ADDITIONAL_REQUIREMENTS_HELPTEXT_LI1}</li>
          <li>${TRANSLATED.ADDITIONAL_REQUIREMENTS_HELPTEXT_LI2}</li>
          <li>${TRANSLATED.ADDITIONAL_REQUIREMENTS_HELPTEXT_LI3}</li>
        </ul>
      `}"
      bind:value="{$scopeStore['ADDITIONAL_REQUIREMENTS']}"
      deutsch
    />
  </form>
</Page>

<script>
  import { getContext, onMount } from 'svelte';

  import {
    CONFORMANCE_LEVELS,
    WCAG_VERSIONS,
    scopedWcagVersions,
    APP_TYPES,
    APP_TYPE_NAMES,
    REPORT_TYPES,
    REPORT_TYPE_NAMES,
    STATEMENT_COMPLIANCE_TYPES,
    STATEMENT_COMPLIANCE_TYPE_NAMES
  } from '@app/stores/wcagStore.js';
  import assertions from '@app/stores/earl/assertionStore/index.js';
  import { CriteriaSelected } from '@app/stores/selectedCriteriaStore.js';
  import tests from '@app/stores/earl/testStore/index.js';
  import subjects, {
    TestSubjectTypes
  } from '@app/stores/earl/subjectStore/index.js';
  import { interactedOpenEvaluation } from '@app/stores/interactedStore.js';

  import Page from '@app/components/ui/Page.svelte';
  import Input from '@app/components/form/Input.svelte';
  import Select from '@app/components/form/Select.svelte';
  import Textarea from '@app/components/form/Textarea.svelte';
  import ResourceLink from '@app/components/ui/ResourceLink.svelte';

  const { translate } = getContext('app');
  $: TRANSLATED = {
    PAGE_TITLE: $translate('PAGES.SCOPE.TITLE'),
    INTRODUCTION: $translate('PAGES.SCOPE.INTRO'),
    RESOURCE_LINK_NAME: $translate('PAGES.SCOPE.RESOURCE_LINK_NAME'),
    SITE_NAME_LABEL: $translate('PAGES.SCOPE.LABEL_SITE_NAME'),
    SITE_NAME_HELPTEXT: $translate('PAGES.SCOPE.INF_SITE_NAME'),
    SITE_SCOPE_LABEL: $translate('PAGES.SCOPE.LABEL_SITE_SCOPE'),
    SITE_SCOPE_HELPTEXT_P1: $translate('PAGES.SCOPE.INF_SITE_SCOPE_0'),
    SITE_SCOPE_HELPTEXT_LI1: $translate('PAGES.SCOPE.INF_SITE_SCOPE_LI0'),
    SITE_SCOPE_HELPTEXT_LI2: $translate('PAGES.SCOPE.INF_SITE_SCOPE_LI1'),
    SITE_SCOPE_HELPTEXT_LI3: $translate('PAGES.SCOPE.INF_SITE_SCOPE_LI2'),
    WCAG_VERSION_LABEL: $translate('PAGES.SCOPE.LABEL_WCAG_VERSION'),
    WCAG_VERSION_HELPTEXT: $translate('PAGES.SCOPE.INFO_WCAG_VERSION'),
    CONFORMANCE_TARGET_LABEL: $translate('PAGES.SCOPE.LABEL_CONFORMANCE_TGT'),
    CONFORMANCE_TARGET_HELPTEXT: $translate('PAGES.SCOPE.INF_CONF_TGT'),
    ACCESSIBILITY_SUPPORT_BASELINE_LABEL: $translate('PAGES.SCOPE.LABEL_SUPPORT_BASE'),
    ACCESSIBILITY_SUPPORT_BASELINE_HELPTEXT: $translate('PAGES.SCOPE.INF_SUPPORT_BASE'),
    ADDITIONAL_REQUIREMENTS_LABEL: $translate('PAGES.SCOPE.LABEL_EXTRA_REQUIREMENTS'),
    ADDITIONAL_REQUIREMENTS_HELPTEXT_P1: $translate('PAGES.SCOPE.INF_EXTRA_REQUIREMENTS_0'),
    ADDITIONAL_REQUIREMENTS_HELPTEXT_LI1: $translate('PAGES.SCOPE.INF_EXTRA_REQUIREMENTS_LI0'),
    ADDITIONAL_REQUIREMENTS_HELPTEXT_LI2: $translate('PAGES.SCOPE.INF_EXTRA_REQUIREMENTS_LI1'),
    ADDITIONAL_REQUIREMENTS_HELPTEXT_LI3: $translate('PAGES.SCOPE.INF_EXTRA_REQUIREMENTS_LI2'),
    CONFORMANCE_LEVEL: $translate('WCAG.COMMON.CONFORMANCE_LEVEL'),
    DATA_LOSS_WARNING: $translate('PAGES.SCOPE.DATA_LOSS_WARNING'),
    FFG_WCAG_VERSION_NOTE: $translate('PAGES.SCOPE.FFG_WCAG_VERSION_NOTE'),
    FFG_APP_TYPE_LABEL: $translate('PAGES.SCOPE.FFG_LABEL_APP_TYPE'),
    FFG_APP_TYPE_HELPTEXT: $translate('PAGES.SCOPE.FFG_INFO_APP_TYPE'),
    FFG_REPORT_TYPE_LABEL: $translate('PAGES.SCOPE.FFG_LABEL_REPORT_TYPE'),
    FFG_REPORT_TYPE_HELPTEXT: $translate('PAGES.SCOPE.FFG_INFO_REPORT_TYPE'),
    FFG_STATEMENT_URL_LABEL: $translate('PAGES.SCOPE.FFG_LABEL_STATEMENT_URL'),
    FFG_STATEMENT_URL_HELPTEXT: $translate('PAGES.SCOPE.FFG_INFO_STATEMENT_URL'),
    FFG_STATEMENT_COMPLIANCE_LABEL: $translate('PAGES.SCOPE.FFG_LABEL_STATEMENT_COMPLIANCE'),
    FFG_STATEMENT_COMPLIANCE_HELPTEXT: $translate('PAGES.SCOPE.FFG_INFO_STATEMENT_COMPLIANCE'),
    FFG_REQUIRED: $translate('PAGES.SCOPE.FFG_REQUIRED')
  };

  let wcagVersions = [...WCAG_VERSIONS].reverse().map((version) => {
    return {
      title: `WCAG ${version}`,
      value: version
    };
  });

  let appTypes = [...APP_TYPES].map((type, idx) => {
    return {
      title: APP_TYPE_NAMES[idx],
      value: type
    };
  });

  let reportTypes = [...REPORT_TYPES].map((type, idx) => {
    return {
      title: REPORT_TYPE_NAMES[idx],
      value: type
    };
  });
  
  let statementComplianceTypes = [...STATEMENT_COMPLIANCE_TYPES].map((type, idx) => {
    return {
      title: STATEMENT_COMPLIANCE_TYPE_NAMES[idx],
      value: type
    };
  });

  $: conformanceLevels = CONFORMANCE_LEVELS.map((level) => {
    return {
      title: `${TRANSLATED.CONFORMANCE_LEVEL} ${level}`,
      value: level
    };
  });

  const { scopeStore } = getContext('app');

  let oldwcag = $scopeStore['WCAG_VERSION'];
  let oldtarget = $scopeStore['CONFORMANCE_TARGET'];
  let oldAppType = $scopeStore['APP_TYPE'];
  let oldReportType = $scopeStore['REPORT_TYPE'];
  let oldStatementCompliance = $scopeStore['STATEMENT_COMPLIANCE'];

  function updateWCAGversion(event){
    oldwcag = $scopeStore['WCAG_VERSION'];
    $scopeStore['WCAG_VERSION'] = event.target.value;
  }

  function updateConformanceTarget(event){
    oldtarget = $scopeStore['CONFORMANCE_TARGET'];
    $scopeStore['CONFORMANCE_TARGET'] = event.target.value;
  }

  function updateAppType(event){
    oldAppType = $scopeStore['APP_TYPE'];
    $scopeStore['APP_TYPE'] = event.target.value;
  }

  function updateReportType(event){
    oldReportType = $scopeStore['REPORT_TYPE'];
    $scopeStore['REPORT_TYPE'] = event.target.value;
  }

  function updateStatementCompliance(event){
    oldStatementCompliance = $scopeStore['STATEMENT_COMPLIANCE'];
    $scopeStore['STATEMENT_COMPLIANCE'] = event.target.value;
  }

  // Used to display subject.title
  export let subject = {};

  // Used for id creation (test.id)
  export let test = {};

  let assertionsToRemove = [];
  $: {
    // Get or create an Assertion
    const available = [];
    $CriteriaSelected.forEach((criteria) => {
      const check = criteria.num;
      available.push(check);
      subject = $subjects.find((subject) => {
        return subject.type.indexOf(TestSubjectTypes.WEBSITE) >= 0;
    });

    test = $tests.find(($test) => {
      return $test.num === check;
    });
      
    $assertions.find(($assertion) => {
      const matchedTest = $assertion.test === test;
      const matchedSubject = $assertion.subject === subject;

      return matchedTest && matchedSubject;
      }) || assertions.create({ subject, test });
    });

    assertionsToRemove = $assertions.filter((assertion) => {
      return available.indexOf(assertion.test.num) == -1;
    });

    if(assertionsToRemove.length > 0){
      let answeredCount = 0;
      assertionsToRemove.forEach((assertion) => {
        if(assertion.result.outcome.id != "earl:untested"){
          answeredCount++;
        }
      });

      if(answeredCount > 0){
        let allowDataLoss = true;
        if($interactedOpenEvaluation != true){
          allowDataLoss = window.confirm(TRANSLATED.DATA_LOSS_WARNING)
        }
        if (allowDataLoss) {
          assertionsToRemove.forEach((assertion) => {
            assertions.remove(assertion);
          });
        } else {
          $scopeStore['WCAG_VERSION'] = oldwcag;
          $scopeStore['CONFORMANCE_TARGET'] = oldtarget;
          $scopeStore['APP_TYPE'] = oldAppType;
          $scopeStore['REPORT_TYPE'] = oldReportType;
          $scopeStore['STATEMENT_COMPLIANCE'] = oldStatementCompliance;
        }
      }else{
        assertionsToRemove.forEach((assertion) => {
          assertions.remove(assertion);
        });
      }
    }
    $interactedOpenEvaluation = false;
  }  

</script>
