<!-- src/components/ui/Report/ReportAllResults.svelte -->
<script>
  import { getContext } from 'svelte';
  import { Link } from 'svelte-navigator';
  import marked from 'marked';

  // Store imports
  import { WCAG_VERSIONS } from '@app/stores/wcagStore.js';
  import scopeStore from '@app/stores/scopeStore.js';
  import assertions from '@app/stores/earl/assertionStore/index.js';
  import subjects, { TestSubjectTypes } from '@app/stores/earl/subjectStore/index.js';
  import { TestSubject } from '@app/stores/earl/subjectStore/models.js';

  export let criteria = [];

  // Access translation/internationalization
  const { translate, translateToObject } = getContext('app');

  // 1) Check if user picked "Simplified evaluation"
  $: isSimplified = ($scopeStore['REPORT_TYPE'] === 'report:simple');

  $: console.log('Current REPORT_TYPE:', $scopeStore['REPORT_TYPE']);

  // 2) Build translation map
  $: TRANSLATED = {
    PRINCIPLES: $translateToObject('WCAG.PRINCIPLE'),
    GUIDELINES: $translateToObject('WCAG.GUIDELINE'),
    CRITERIA: $translateToObject('WCAG.SUCCESS_CRITERION'),
    LABEL_OUTCOME: $translate('PAGES.AUDIT.LABEL_OUTCOME'),
    LABEL_OBSERVATION: $translate('PAGES.AUDIT.ASSERTION_RESULT_DESCRIPTION_LABEL'),
    HEADING_SCOPE_RESULTS: $translate('PAGES.AUDIT.SAMPLE_FINDINGS'),
    HEADING_RESULTS_FOR: $translate('PAGES.AUDIT.RESULTS_FOR'),
    TEXT_NOT_CHECKED: $translate('UI.EARL.UNTESTED'),
    HEADER_SUCCESS_CRITERION: $translate('PAGES.REPORT.HEADER_SUCCESS_CRITERION'),
    HEADER_RESULT: $translate('PAGES.REPORT.HEADER_RESULT'),
    HEADER_OBSERVATIONS: $translate('PAGES.REPORT.HEADER_OBSERVATIONS'),
    NO_OBSERVATIONS_FOUND: $translate('PAGES.REPORT.NO_OBSERVATIONS_FOUND'),
    EDIT: $translate('UI.REPORT.EDIT')
  };

  // 3) Identify principle and guideline sets
  $: principles = [...new Set(criteria.map(a => a.num.split('.')[0]))];
  $: guidelines = [
    ...new Set(
      criteria.map(a => {
        const parts = a.num.split('.');
        return `${parts[0]}.${parts[1]}`;
      })
    )
  ];

  // 4) Filter by WCAG version and conformance
  function filterAssertions() {
    return criteria
      .filter(crit => {
        const filterVersions = WCAG_VERSIONS;
        if (filterVersions.length === 0) return true;
        return filterVersions.includes(crit.version);
      })
      .filter(crit => {
        // For example "AA,EN" or $scopeStore['CONFORMANCE_TARGET']
        const filterLevels = 'AA,EN';
        if (!filterLevels) return true;
        return filterLevels.includes(crit.conformanceLevel);
      });
  }

  // 5) Return only criteria that match a guideline prefix
  function guidelineCriteria(guideline) {
    return filterAssertions().filter(c => c.num.startsWith(guideline));
  }

  // 6) Generic helper for retrieving all assertions for a criterion
  function criterionAssertions(criterion) {
    return $assertions.filter(a => a.test.num === criterion.num);
  }

  // 7) Website-level (scope) assertions
  function scopeAssertion(criterion) {
    return criterionAssertions(criterion).filter(a =>
      a.subject.type.includes(TestSubjectTypes.WEBSITE)
    );
  }

  // 8) Sample-level (page) assertions
  function sampleAssertions(criterion) {
    const sampleAsserts = criterionAssertions(criterion).filter(a =>
      a.subject.type.includes(TestSubjectTypes.WEBPAGE)
    );
    sampleAsserts.sort(sortSubjectOrder);
    return sampleAsserts;
  }

  // Sort logic for pages (based on $subjects order)
  function sortSubjectOrder(a, b) {
    const titles = [];
    $subjects.forEach(s => titles.push(s.title));
    return titles.indexOf(a.subject.title) - titles.indexOf(b.subject.title);
  }

  // Does the assertion have an actual outcome or description to show?
  function assertionHasContents(assertion) {
    return (
      (assertion.result.outcome.title &&
        assertion.result.outcome.title !== TRANSLATED.TEXT_NOT_CHECKED) ||
      assertion.result.description
    );
  }

  // For "Simplified" mode: decide if the entire row should appear.
  // If all outcomes are "Not checked" or undefined, we skip the row.
  function hasValidAssertion(criterion) {
    const allAsserts = [...scopeAssertion(criterion), ...sampleAssertions(criterion)];
    // True if at least one outcome != "Not checked" or undefined
    return allAsserts.some(a =>
      a.result.outcome.title &&
      a.result.outcome.title !== TRANSLATED.TEXT_NOT_CHECKED
    );
  }
</script>

<!-- ======================
     Template Section
     ====================== -->
{#if isSimplified}
  <!-- ************************************
      SIMPLIFIED EVALUATION LAYOUT
      ************************************ -->
      {#each principles as principle}
      <h4>{principle} {TRANSLATED.PRINCIPLES[principle].TITLE}</h4>
  
      {#each guidelines.filter(g => g.startsWith(principle)) as guideline}
        <!-- We figure out how many valid rows exist -->
        {#if guidelineCriteria(guideline).some(c => hasValidAssertion(c))}
          <!-- If there is at least one valid row, display the table and headers -->
          <h5 id={`guideline-${guideline.replace('.', '')}`}>
            {guideline} {TRANSLATED.GUIDELINES[guideline].TITLE}
          </h5>
          <table class="Auditor__ResultsTable" aria-labelledby={`guideline-${guideline.replace('.', '')}`}>
            <tbody>
              <tr class="Auditor__ResultsTableHeader">
                <th scope="col">{TRANSLATED.HEADER_SUCCESS_CRITERION}</th>
                <th scope="col">{TRANSLATED.HEADER_RESULT}</th>
                <th scope="col">{TRANSLATED.HEADER_OBSERVATIONS}</th>
                <th scope="col" class="strip">{TRANSLATED.EDIT}</th>
              </tr>
  
              {#each guidelineCriteria(guideline) as criterion (criterion.num)}
                {#if hasValidAssertion(criterion)}
                  <tr class="Auditor__Assertion">
                    <!-- SC Label -->
                    <th
                      scope="row"
                      class="Auditor__Assertion-SC"
                      id={`criterion-${criterion.num.replaceAll('.', '')}`}
                    >
                      {criterion.num}: {TRANSLATED.CRITERIA[criterion.num].TITLE}
                    </th>
  
                    <!-- Results column -->
                    <td>
                      <!-- scope-level results, excluding "Not checked"/undefined -->
                      {#each scopeAssertion(criterion).filter(a =>
                        a.result.outcome.title &&
                        a.result.outcome.title !== TRANSLATED.TEXT_NOT_CHECKED
                      ) as assertion}
                        {#if sampleAssertions(criterion).length}
                          <h6>{TRANSLATED.HEADING_SCOPE_RESULTS}</h6>
                        {/if}
                        <p>
                          <span class="results-label-mobile">{TRANSLATED.HEADER_RESULT}:</span>
                          {assertion.result.outcome.title}
                        </p>
                      {/each}
  
                      <!-- sample-level results, excluding "Not checked"/undefined -->
                      {#each sampleAssertions(criterion).filter(a =>
                        a.result.outcome.title &&
                        a.result.outcome.title !== TRANSLATED.TEXT_NOT_CHECKED
                      ) as assertion}
                        {#if assertionHasContents(assertion)}
                          <h6 lang="de">
                            {assertion.subject.title || `Sample ${assertion.subject.ID}`}
                          </h6>
                          <p>
                            <span class="results-label-mobile">{TRANSLATED.HEADER_RESULT}:</span>
                            {assertion.result.outcome.title}
                          </p>
                        {/if}
                      {/each}
                    </td>
  
                    <!-- Observations column -->
                    <td>
                      {#each scopeAssertion(criterion).filter(a =>
                        a.result.outcome.title &&
                        a.result.outcome.title !== TRANSLATED.TEXT_NOT_CHECKED
                      ) as assertion}
                        {#if assertion.result.description}
                          {#if sampleAssertions(criterion).filter(a =>
                            a.result.outcome.title &&
                            a.result.outcome.title !== TRANSLATED.TEXT_NOT_CHECKED
                          ).length}
                            <h6>{TRANSLATED.HEADING_SCOPE_RESULTS}</h6>
                          {/if}
                          <p class="results-label-mobile">{TRANSLATED.LABEL_OBSERVATION}:</p>
                          <div lang="de">
                            {@html marked(assertion.result.description, { sanitize: true })}
                          </div>
                        {/if}
                      {/each}
  
                      {#each sampleAssertions(criterion).filter(a =>
                        a.result.outcome.title &&
                        a.result.outcome.title !== TRANSLATED.TEXT_NOT_CHECKED
                      ) as assertion}
                        {#if assertionHasContents(assertion)}
                          <h6 lang="de">
                            {assertion.subject.title || `Sample ${assertion.subject.ID}`}
                          </h6>
                          {#if assertion.result.description}
                            <div lang="de">
                              {@html marked(assertion.result.description, { sanitize: true })}
                            </div>
                          {:else}
                            <p>{TRANSLATED.NO_OBSERVATIONS_FOUND}</p>
                          {/if}
                        {/if}
                      {/each}
                    </td>
  
                    <!-- Edit link column -->
                    <td class="strip">
                      <Link
                        to={`/evaluation/${
                          criterion.conformanceLevel == 'EN' ? 'ffg-' : ''
                        }audit-sample#criterion-${criterion.num.replaceAll('.', '')}`}
                      >
                        <span class="visuallyhidden">Edit {criterion.num}</span>
                        <svg
                          aria-hidden="true"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-edit"
                        >
                          <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                          <path
                            d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                          />
                        </svg>
                      </Link>
                    </td>
                  </tr>
                {/if}
              {/each}
            </tbody>
          </table>
        {:else}
          <!-- If no valid rows, hide the table and show the message -->
          <h5 id={`guideline-${guideline.replace('.', '')}`}>
            {guideline} {TRANSLATED.GUIDELINES[guideline].TITLE}
          </h5>
          <p style="margin: 1em 0;">
            No associated criterion included in the simplified evaluation
          </p>
        {/if}
      {/each}
    {/each}
  {:else}
  <!-- ************************************
       ORIGINAL (DEFAULT) EVALUATION MODE
       ************************************ -->
  {#each principles as principle}
    <h4>{principle} {TRANSLATED.PRINCIPLES[principle].TITLE}</h4>

    {#each guidelines.filter(g => g.indexOf(principle) === 0) as guideline}
      <h5 id={`guideline-${guideline.replace('.', '')}`}>
        {guideline} {TRANSLATED.GUIDELINES[guideline].TITLE}
      </h5>
      <table class="Auditor__ResultsTable" aria-labelledby={`guideline-${guideline.replace('.', '')}`}>
        <tbody>
          <tr class="Auditor__ResultsTableHeader">
            <th scope="col">{TRANSLATED.HEADER_SUCCESS_CRITERION}</th>
            <th scope="col">{TRANSLATED.HEADER_RESULT}</th>
            <th scope="col">{TRANSLATED.HEADER_OBSERVATIONS}</th>
            <th scope="col" class="strip">{TRANSLATED.EDIT}</th>
          </tr>

          {#each guidelineCriteria(guideline) as criterion (criterion.num)}
            <tr class="Auditor__Assertion">
              <th
                scope="row"
                class="Auditor__Assertion-SC"
                id={`criterion-${criterion.num.replaceAll('.', '')}`}
              >
                {criterion.num}: {TRANSLATED.CRITERIA[criterion.num].TITLE}
              </th>
              <td>
                {#each scopeAssertion(criterion) as assertion}
                  {#if sampleAssertions(criterion).length}
                    <h6>{TRANSLATED.HEADING_SCOPE_RESULTS}</h6>
                  {/if}
                  <p>
                    <span class="results-label-mobile">{TRANSLATED.HEADER_RESULT}:</span>
                    {assertion.result.outcome.title || TRANSLATED.TEXT_NOT_CHECKED}
                  </p>
                {:else}
                  <p>
                    <span class="results-label-mobile">{TRANSLATED.HEADER_RESULT}:</span>
                    {TRANSLATED.TEXT_NOT_CHECKED}
                  </p>
                {/each}

                {#if sampleAssertions(criterion).length}
                  {#each sampleAssertions(criterion) as assertion}
                    {#if assertionHasContents(assertion)}
                      <h6 lang="de">
                        {assertion.subject.title || `Sample ${assertion.subject.ID}`}
                      </h6>
                      <p>
                        <span class="results-label-mobile">{TRANSLATED.HEADER_RESULT}:</span>
                        {assertion.result.outcome.title || TRANSLATED.TEXT_NOT_CHECKED}
                      </p>
                    {/if}
                  {/each}
                {/if}
              </td>
              <td>
                {#each scopeAssertion(criterion) as assertion}
                  {#if assertion.result.description}
                    {#if sampleAssertions(criterion).length}
                      <h6>{TRANSLATED.HEADING_SCOPE_RESULTS}</h6>
                    {/if}
                    <p class="results-label-mobile">{TRANSLATED.LABEL_OBSERVATION}:</p>
                    <div lang="de">
                      {@html marked(assertion.result.description, { sanitize: true })}
                    </div>
                  {/if}
                {/each}

                {#if sampleAssertions(criterion).length}
                  {#each sampleAssertions(criterion) as assertion}
                    {#if assertionHasContents(assertion)}
                      <h6 lang="de">
                        {assertion.subject.title || `Sample ${assertion.subject.ID}`}
                      </h6>
                      {#if assertion.result.description}
                        <div lang="de">
                          {@html marked(assertion.result.description, { sanitize: true })}
                        </div>
                      {:else}
                        <p>{TRANSLATED.NO_OBSERVATIONS_FOUND}</p>
                      {/if}
                    {/if}
                  {/each}
                {/if}
              </td>
              <td class="strip">
                <Link
                  to={`/evaluation/${
                    criterion.conformanceLevel == 'EN' ? 'ffg-' : ''
                  }audit-sample#criterion-${criterion.num.replaceAll('.', '')}`}
                >
                  <span class="visuallyhidden">Edit {criterion.num}</span>
                  <svg
                    aria-hidden="true"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-edit"
                  >
                    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                    <path
                      d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                    />
                  </svg>
                </Link>
              </td>
            </tr>
          {/each}
        </tbody>
      </table>
    {/each}
  {/each}
{/if}

<style>
  .Auditor__ResultsTable {
    width: 100%;
  }

  h5 {
    color: var(--wai-green);
    margin: 1.5em 0 1em;
  }

  .Auditor__ResultsTable th {
    width: 30%;
    position: absolute;
    left: -9999em;
    top: -9999em;
  }
  .Auditor__ResultsTable th:nth-child(2) {
    width: 25%;
  }
  .Auditor__ResultsTable th:nth-child(3) {
    width: 60%;
  }

  .Auditor__ResultsTableHeader {
    position: sticky;
    top: 0;
  }

  .Auditor__Assertion {
    margin-bottom: 1em;
    border-bottom: 1px solid var(--cloudy);
  }

  .Auditor__Assertion-SC {
    font-weight: bold;
    color: inherit;
    background-color: inherit;
  }

  .Auditor__ResultsTable td {
    display: block;
    position: relative;
    border-style: none;
  }

  @media (min-width: 60em) {
    .Auditor__Assertion {
      border-bottom: 0;
    }
    .Auditor__Assertion-SC {
      font-weight: normal;
    }
    .Auditor__ResultsTable td {
      display: table-cell;
      border: 1px solid var(--cloudy);
    }
    .Auditor__ResultsTable th {
      position: static;
    }
    .results-label-mobile {
      display: none;
    }
  }
</style>
