<!--
 * @component
 *   Report
 * -->
<div tabindex="-1" bind:this={sectionAbout}>
  <h2>{TRANSLATED.HEADING_ABOUT}</h2>
  <dl>

    <dt>
      <ReportHeaderKey editing={editAbout} field="EVALUATION_CREATOR">
        {TRANSLATED.LABEL_EVALUATOR}
      </ReportHeaderKey>
    </dt>
    <dd>
      <ReportHeaderValue editing={editAbout} field="EVALUATION_CREATOR" store="summaryStore" deutsch></ReportHeaderValue>
    </dd>

    <dt>
      <ReportHeaderKey editing={editAbout} field="EVALUATION_COMMISSIONER">
        {TRANSLATED.LABEL_COMMISSIONER}
      </ReportHeaderKey>
    </dt>
    <dd>
      <ReportHeaderValue editing={editAbout} field="EVALUATION_COMMISSIONER" store="summaryStore" deutsch/>
    </dd>

    <!-- FFG: additional evaluation fields -->
    <dt>
      <ReportHeaderKey editing={editAbout} field="STATE">
        {TRANSLATED.FFG_ADMINISTRATION_LEVEL}
      </ReportHeaderKey>
    </dt>
    <dd>
      <ReportHeaderMultiValue editing={editAbout} field="STATE" store="summaryStore" options={states}></ReportHeaderMultiValue>
    </dd>

    <dt>
      <ReportHeaderKey editing={editAbout} field="EVALUATION_DATE">
        {TRANSLATED.LABEL_DATE}
      </ReportHeaderKey>
    </dt>
    <dd>
      <ReportHeaderValue editing={editAbout} field="EVALUATION_DATE" store="summaryStore" />
    </dd>
  </dl>

  {#if editAbout}
  <button class="button button-secondary" on:click={toggleEditAbout}>
    {TRANSLATED.BUTTON_SAVE}  
    <span class="visually-hidden">{TRANSLATED.HEADING_ABOUT}</span>
  </button>
  {:else}
  <button class="button button-secondary" on:click={toggleEditAbout}>
    {TRANSLATED.BUTTON_EDIT}
    <span class="visually-hidden">{TRANSLATED.HEADING_ABOUT}</span>
  </button>
  {/if}
</div>

<div tabindex="-1" bind:this={sectionExecutiveSummary}>
  <h2>{TRANSLATED.HEADING_SUMMARY}</h2>
  {#if editExecutiveSummary}
    <div>
      <ReportHeaderValue 
        editing={editExecutiveSummary} 
        multiline={true} 
        field="EVALUATION_SUMMARY" 
        store="summaryStore"
        deutsch>
      </ReportHeaderValue>
    </div>
    <button class="button button-secondary" on:click={toggleEditExecutiveSummary}>
      {TRANSLATED.BUTTON_SAVE} 
      <span class="visually-hidden">{TRANSLATED.HEADING_SUMMARY}</span>
    </button>  
  {:else}
    <div>
    {#if $summaryStore['EVALUATION_SUMMARY']}
    <div lang="de">{@html marked($summaryStore['EVALUATION_SUMMARY'])}</div>
    {:else}
      <span class="no-result">{TRANSLATED.LABEL_NOT_PROVIDED}</span>
    {/if}
    </div>
    <button class="button button-secondary" on:click={toggleEditExecutiveSummary}>
      {TRANSLATED.BUTTON_EDIT} 
      <span class="visually-hidden">{TRANSLATED.HEADING_SUMMARY}</span>
    </button>
  {/if}
</div>

<div tabindex="-1" bind:this={sectionEvaluationScope}>
  <h2>{TRANSLATED.HEADING_SCOPE}</h2>
  <dl>
    <dt>
      <ReportHeaderKey editing={editEvaluationScope} field="SITE_NAME">
        {TRANSLATED.LABEL_WEBSITE_NAME}
      </ReportHeaderKey>
    </dt>
    <dd>
      <ReportHeaderValue editing={editEvaluationScope} field="SITE_NAME" store="scopeStore" deutsch />
    </dd>

    <dt>
      <ReportHeaderKey editing={editEvaluationScope} field="WEBSITE_SCOPE">
        {TRANSLATED.LABEL_WEBSITE_SCOPE}
      </ReportHeaderKey>
    </dt>
    <dd>
      <ReportHeaderValue editing={editEvaluationScope} field="WEBSITE_SCOPE" store="scopeStore" multiline={true} deutsch/>
    </dd>

    <!-- FFG: additional evaluation fields -->
    <dt>
      <ReportHeaderKey editing={editEvaluationScope} field="APP_TYPE">
        {TRANSLATED.FFG_APPLICATION_TYPE}
      </ReportHeaderKey>
    </dt>
    <dd>
      <ReportHeaderMultiValue editing={editEvaluationScope} field="APP_TYPE" store="scopeStore" options={appTypes}></ReportHeaderMultiValue>
    </dd>

    <dt>
      <ReportHeaderKey editing={editEvaluationScope} field="REPORT_TYPE">
        {TRANSLATED.FFG_REPORT_TYPE}
      </ReportHeaderKey>
    </dt>
    <dd>
      <ReportHeaderMultiValue editing={editEvaluationScope} field="REPORT_TYPE" store="scopeStore" options={reportTypes}></ReportHeaderMultiValue>
    </dd>

    <dt>
      <ReportHeaderKey editing={false}>
        European Standard
      </ReportHeaderKey>
    </dt>
    <dd>
      EN 301 549
    </dd>

    <!-- FFG: disable editing of WCAG Version and Conformancetarget, and set Conformance target to 'AA'-->
    <dt>
      <ReportHeaderKey editing={false} field="WCAG_VERSION">
        {TRANSLATED.LABEL_WCAG_VERSION}
      </ReportHeaderKey>
    </dt>
    <dd>
      <ReportHeaderMultiValue editing={false} field="WCAG_VERSION" store="scopeStore" options={wcagVersions}></ReportHeaderMultiValue>
    </dd>

    <dt>
      <ReportHeaderKey editing={false} field="CONFORMANCE_TARGET">
        {TRANSLATED.LABEL_CONFORMANCE_TARGET}
      </ReportHeaderKey>
    </dt>
    <dd>
      AA<!--<ReportHeaderMultiValue editing={false} field="CONFORMANCE_TARGET" store="scopeStore" options={conformanceLevels}></ReportHeaderMultiValue>-->
    </dd>

    <!-- FFG: additional evaluation fields -->
    <dt>
      <ReportHeaderKey editing={editEvaluationScope} field="STATEMENT_URL">
        {TRANSLATED.FFG_LABEL_STATEMENT_URL}
      </ReportHeaderKey>
    </dt>
    <dd>
      <ReportHeaderValue editing={editEvaluationScope} field="STATEMENT_URL" store="scopeStore" checkURL/>
    </dd>

    <dt>
      <ReportHeaderKey editing={editEvaluationScope} field="STATEMENT_COMPLIANCE">
        {TRANSLATED.FFG_LABEL_STATEMENT_COMPLIANCE}
      </ReportHeaderKey>
    </dt>
    <dd>
      <ReportHeaderMultiValue editing={editEvaluationScope} field="STATEMENT_COMPLIANCE" store="scopeStore" options={complianceTypes}></ReportHeaderMultiValue>
    </dd>

    <dt>
      <ReportHeaderKey editing={editEvaluationScope} field="AS_BASELINE">
        {TRANSLATED.LABEL_ACCESSIBILITY_SUPPORT_BASELINE}
      </ReportHeaderKey>
    </dt>
    <dd>
      <ReportHeaderValue editing={editEvaluationScope} field="AS_BASELINE" store="scopeStore" multiline={true} deutsch />
    </dd>

    <dt>
      <ReportHeaderKey editing={editEvaluationScope} field="ADDITIONAL_REQUIREMENTS">
        {TRANSLATED.LABEL_EXTRA_REQUIREMENTS}
      </ReportHeaderKey>
    </dt>
    <dd>
      <ReportHeaderValue editing={editEvaluationScope} field="ADDITIONAL_REQUIREMENTS" store="scopeStore" multiline={true} deutsch />
    </dd>
  </dl>

  {#if editEvaluationScope}
  <button class="button button-secondary" on:click={toggleEditEvaluationScope}>
    {TRANSLATED.BUTTON_SAVE}  
    <span class="visually-hidden">{TRANSLATED.HEADING_SCOPE}</span>
  </button>
  {:else}
  <button class="button button-secondary" on:click={toggleEditEvaluationScope}>
    {TRANSLATED.BUTTON_EDIT}
    <span class="visually-hidden">{TRANSLATED.HEADING_SCOPE}</span>
  </button>
  {/if}
</div>

<h2>{TRANSLATED.HEADING_AUDIT_RESULTS_DETAIL}</h2>

<!-- FFG: add note about compliance  -->
<span lang="de">
  {TRANSLATED.FFG_COMPLIANCE_NOTE}
</span>

<!-- FFG: split results into WCAG and EN criteria
<h3>{TRANSLATED.ALL_RESULTS}</h3>
<ReportAllResults criteria="{$wcag}" />
-->

<h3>{TRANSLATED.FFG_SUMMARY_WCAG}</h3>
<ReportSummary />
{#if $scopeStore['REPORT_TYPE'] == 'report:in-depth'} <!-- FFG: show EN criteria summary if in-depth report-->
  <h3>{TRANSLATED.FFG_SUMMARY_EN}</h3>
  <FFG_ReportSummary />
{/if}

<h3>{TRANSLATED.FFG_WCAG_RESULTS}</h3>
<ReportAllResults criteria="{$wcag.filter(item => item.conformanceLevel !== 'EN')}" />
{#if $scopeStore['REPORT_TYPE'] == 'report:in-depth'} <!-- FFG: show EN criteria if in-depth report-->
  <h3>{TRANSLATED.FFG_EN_RESULTS}</h3>
  <ReportAllResults criteria="{$wcag.filter(item => item.conformanceLevel == 'EN')}" />
{/if}

<h2>{TRANSLATED.HEADING_SAMPLE}</h2>
{#if report.samples.length > 0}
  <ol>
    {#each report.samples as sample}
      <li>
        <span lang="de">{sample.title}</span> - <span>
          {#if isValidUrl(sample.description)}
          <a target="_blank" rel="noopener noreferrer" href="{sample.description}">{sample.description}</a>
          {:else}
          {sample.description}
          {/if}</span>
      </li>
    {/each}
  </ol>
{:else}
  <p>{TRANSLATED.TEXT_NO_SAMPLE}</p>
{/if}

<h2>{TRANSLATED.LABEL_TECH}</h2>
{#if report.tech != ""}
  <p>{report.tech.join(", ")}</p>
{:else}
  <p>{TRANSLATED.LABEL_NOT_PROVIDED}</p>
{/if}

<h2>{TRANSLATED.HEADING_SPECIFICS}</h2>
{#if report.specifics}
<p lang="de">{@html marked(report.specifics)}</p>
{:else}
<p>{TRANSLATED.LABEL_NOT_PROVIDED}</p>
{/if}

<h2 class='strip'>{TRANSLATED.HEADING_RESOURCES}</h2>
<ul class='strip'>
  <li><a href="https://www.w3.org/WAI/intro/wcag">Web Content Accessibility Guidelines (WCAG)
    Overview</a></li>
  <li><a href="https://www.w3.org/WAI/WCAG21/quickref/">How to Meet WCAG 2.1 Quick Reference</a></li>
  <li><a href="https://www.w3.org/WAI/eval/conformance">WCAG Evaluation Methodology (WCAG-EM)
    Overview</a></li>
</ul>
<!-- /component -->

<style>
  dt {
    grid-column: 1 / 2;
    margin-top: 0;
    font-weight: normal;
  }
  dt:after {
    content: ":";
  }
  dd {
    font-weight: bold;
    margin-left: 0;
    margin-bottom: 1em;
    grid-column: 2 / 3;
  }
  @media (min-width: 40em) {
    dl {
      display: grid;
      grid-template-columns: auto 2fr;
      gap: 0.5em 1em;
    }
    dd {
      margin-bottom: 0;
    }
  }
  .no-result {
    font-weight: normal;
    font-style: italic;
    margin-bottom: 1em;
    display: block;
  }
  </style>

<script>
  import { getContext } from 'svelte';
  import marked from "marked";

  import { wcag, CONFORMANCE_LEVELS, WCAG_VERSIONS, STATES, STATE_NAMES,
    APP_TYPES, APP_TYPE_NAMES, REPORT_TYPES, REPORT_TYPE_NAMES,
    STATEMENT_COMPLIANCE_TYPES, STATEMENT_COMPLIANCE_TYPE_NAMES
  } from '@app/stores/wcagStore.js';

  import ReportAllResults from '@app/components/ui/Report/ReportAllResults.svelte';
  import ReportHeaderKey from '@app/components/ui/Report/ReportHeaderKey.svelte';
  import ReportHeaderValue from '@app/components/ui/Report/ReportHeaderValue.svelte';
  import ReportHeaderMultiValue from '@app/components/ui/Report/ReportHeaderMultiValue.svelte';
  import ReportSummary from './Report/ReportSummary.svelte';
  import FFG_ReportSummary from './Report/FFG_ReportSummary.svelte';
  import evaluationStore from '@app/stores/evaluationStore.js';

  const { scopeStore, sampleStore, summaryStore, exploreStore, translate } = getContext(
    'app'
  );

  let editAbout = false;
  let editExecutiveSummary = false;
  let editEvaluationScope = false;
  let sectionAbout, sectionEvaluationScope, sectionExecutiveSummary;

  $: TRANSLATED = {
    LABEL_EVALUATOR: $translate('UI.REPORT.BY'),
    LABEL_COMMISSIONER: $translate('UI.REPORT.COMMISION_BY'),
    LABEL_NOT_PROVIDED: $translate('UI.REPORT.LABEL_NOT_PROVIDED'),
    LABEL_DATE: $translate('PAGES.SUMMARY.LABEL_DATE'),
    BUTTON_SAVE: $translate('UI.REPORT.SAVE'),
    BUTTON_EDIT: $translate('UI.REPORT.EDIT'),
    HEADING_ABOUT: $translate('UI.REPORT.HD_ABOUT'),
    HEADING_SUMMARY: $translate('UI.REPORT.HD_SUMMARY'),
    HEADING_SCOPE: $translate('UI.REPORT.HD_SCOPE'),
    LABEL_WEBSITE_NAME: $translate('PAGES.SCOPE.LABEL_SITE_NAME'),
    LABEL_WEBSITE_SCOPE: $translate('PAGES.SCOPE.LABEL_SITE_SCOPE'),
    LABEL_WCAG_VERSION: $translate('PAGES.SCOPE.LABEL_WCAG_VERSION'),
    LABEL_CONFORMANCE_TARGET: $translate('PAGES.SCOPE.LABEL_CONFORMANCE_TGT'),
    LABEL_EXTRA_REQUIREMENTS: $translate('PAGES.SCOPE.LABEL_EXTRA_REQUIREMENTS'),
    LABEL_ACCESSIBILITY_SUPPORT_BASELINE: $translate('PAGES.SCOPE.LABEL_SUPPORT_BASE'),
    HEADING_AUDIT_RESULTS_OVERVIEW: $translate('UI.REPORT.HD_SCORE'),
    HEADING_AUDIT_RESULTS_DETAIL: $translate('UI.REPORT.HD_CRITERIA_REPORT'),
    HEADING_SAMPLE: $translate('UI.REPORT.HD_SAMPLE'),
    HEADING_SPECIFICS: $translate('UI.REPORT.HD_SPECIFICS'),
    HEADING_RESOURCES: $translate('UI.REPORT.HD_DOCS'),
    CONFORMANCE_LEVEL: $translate('WCAG.COMMON.CONFORMANCE_LEVEL'),
    TEXT_NO_SAMPLE: $translate('PAGES.AUDIT.NO_SAMPLE'),
    SUMMARY: $translate('UI.REPORT.SUMMARY'),
    ALL_RESULTS: $translate('UI.REPORT.ALL_RESULTS'),
    LABEL_TECH: $translate('PAGES.EXPLORE.LABEL_TECH'),
    FFG_WCAG_RESULTS: $translate('UI.REPORT.FFG_WCAG_RESULTS'),
    FFG_EN_RESULTS: $translate('UI.REPORT.FFG_EN_RESULTS'),
    FFG_ADMINISTRATION_LEVEL: $translate('UI.REPORT.FFG_ADMINISTRATION_LEVEL'),
    FFG_APPLICATION_TYPE: $translate('PAGES.SCOPE.FFG_LABEL_APP_TYPE'),
    FFG_REPORT_TYPE: $translate('PAGES.SCOPE.FFG_LABEL_REPORT_TYPE'),
    FFG_LABEL_STATEMENT_URL: $translate('PAGES.SCOPE.FFG_LABEL_STATEMENT_URL'),
    FFG_LABEL_STATEMENT_COMPLIANCE: $translate('PAGES.SCOPE.FFG_LABEL_STATEMENT_COMPLIANCE'),
    FFG_SUMMARY_WCAG: $translate('UI.REPORT.FFG_SUMMARY_WCAG'),
    FFG_SUMMARY_EN: $translate('UI.REPORT.FFG_SUMMARY_EN'),
    FFG_COMPLIANCE_NOTE: $translate('UI.REPORT.FFG_COMPLIANCE_NOTE')
  };

  $: report = {
    commissioner: $summaryStore['EVALUATION_COMMISSIONER'],
    creator: $summaryStore['EVALUATION_CREATOR'],
    date: $summaryStore['EVALUATION_DATE'],
    samples: [
      ...$sampleStore['STRUCTURED_SAMPLE'],
      ...$sampleStore['RANDOM_SAMPLE']
    ],
    specifics: $summaryStore['EVALUATION_SPECIFICS'],
    summary: $summaryStore['EVALUATION_SUMMARY'],
    title: $summaryStore['EVALUATION_TITLE'] || $translate('PAGES.REPORT.TITLE'),
    tech: $exploreStore['TECHNOLOGIES_RELIED_UPON']
  };

  let wcagVersions = [...WCAG_VERSIONS].reverse().map((version) => {
    return {
      title: `WCAG ${version}`,
      value: version
    };
  });

  $: conformanceLevels = CONFORMANCE_LEVELS.map((level) => {
    return {
      title: `${TRANSLATED.CONFORMANCE_LEVEL} ${level}`,
      value: level
    };
  });

  // FFG: additional evaluation fields
  let states = [...STATES].map((type, idx) => {
    return {
      title: STATE_NAMES[idx],
      value: type
    };
  });

  let appTypes = [...APP_TYPES].map((type, idx) => {
    return {
      title: APP_TYPE_NAMES[idx],
      value: type
    };
  });

  let reportTypes = [...REPORT_TYPES].map((type, idx) => {
    return {
      title: REPORT_TYPE_NAMES[idx],
      value: type
    };
  });

  let complianceTypes = [...STATEMENT_COMPLIANCE_TYPES].map((type, idx) => {
    return {
      title: STATEMENT_COMPLIANCE_TYPE_NAMES[idx],
      value: type
    };
  });

  function toggleEditAbout() {
    editAbout = !editAbout;
    sectionAbout.focus();
  }

  function toggleEditExecutiveSummary() {
    editExecutiveSummary = !editExecutiveSummary;
    sectionExecutiveSummary.focus();
  }

  function toggleEditEvaluationScope() {
    editEvaluationScope = !editEvaluationScope;
    sectionEvaluationScope.focus();
  }

  function isValidUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }
</script>
