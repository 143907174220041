<!-- src/components/form/EarlResult.svelte -->
<!--
 * EarlResult
 *
 * use like:
 *   <EarlResult {...assertion} />
 *
 * Where assertion should be like
 * an earl:Assertion object picked from
 * the assertionStore: $assertions.
 *
 * Important here is to pass the correct result,
 * so test AND subject should always match.
 * -->
<fieldset class="Criterion__Result">
  <legend class="Criterion__Subject">
    {#if label}
      {label}
    {:else}
      {$translate('PAGES.AUDIT.RESULTS_FOR')}: {subject.title || subject.description || `subject ${subject.ID}`}
    {/if}
  </legend>

  <div class="Criterion__Fields">
    <Select
      id="{`assertion__${_assertion.ID}--result__outcome`}"
      label="{$translate('PAGES.AUDIT.LABEL_OUTCOME')}"
      options="{outcomeOptions}"
      value="{_assertion.result.outcome.id}"
      on:change="{handleOutcomeChange}"
    />

    <div class="color-circle" style="background-color: {circleColor};"></div>

    <Textarea
      id="{`assertion__${_assertion.ID}--result__description`}"
      label="{$translate('PAGES.AUDIT.ASSERTION_RESULT_DESCRIPTION_LABEL')}"
      bind:value="{_assertion.result.description}"
      on:change="{handleResultChange}"
      className="Criterion__Observation"
      deutsch
    >
      <span slot="after-textarea" class="view-in-report">
        <Link to={`/evaluation/view-report#criterion-${_assertion.test.num.replaceAll('.', '')}`}>
          {TRANSLATED.VIEW_IN_REPORT}
        </Link>
      </span>
    </Textarea>
  </div>
</fieldset>

<style>
  .Criterion__Result {
    display: block;
    border: none;
    margin: 1em 0 0;
  }

  .Criterion__Subject {
    padding: 0;
    font-size: 1em;
    overflow-wrap: anywhere;
  }
  .Criterion__Fields {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .view-in-report {
    order: -1;
    text-align: right;
  }
  :global(.Criterion__Fields :last-child) {
    flex: 2;
  }
  :global(div.Criterion__Fields .Field:last-child label) {
    order: -2;
  }
  :global(div.Criterion__Fields div.Field.Criterion__Observation) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .color-circle {
    margin: 0;
    padding: 0;
    position: relative;
    top: 20px;
    border-radius: 50%;
    height: 25px;
    width: 25px;
  }

  @media (min-width: 60em) {
    .Criterion__Fields {
      flex-direction: row;
      gap: 2rem;
    }
  }

  @media screen and (min-resolution: 2dppx), screen and (min-resolution: 192dpi) {
    .color-circle {
      top: -18px;
    }
  }
  
  @media screen and (min-width: 960px) and (min-resolution: 2dppx) and (max-resolution: 4dppx),
        screen and (min-width: 960px) and (min-resolution: 192dpi) and (max-resolution: 384dpi) {
    .color-circle {
      top: 23px;
    }
  }

  @media screen and (max-width: 950px) and (max-resolution: 1.75dppx),
       screen and (max-width: 950px) and (max-resolution: 168dpi) {
    .color-circle {
      top: -15px !important;
    }
  }

  @media screen and (min-width: 950px) and (max-width: 1000px) and (resolution: 1.75dppx),
       screen and (min-width: 950px) and (max-width: 1000px) and (resolution: 168dpi) {
    .color-circle {
      top: -15px;
    }
  }

</style>

<script>
  import { getContext } from 'svelte';
  import { Link } from 'svelte-navigator';

  import assertions from '@app/stores/earl/assertionStore/index.js';
  import Select from '@app/components/form/Select.svelte';
  import Textarea from '@app/components/form/Textarea.svelte';

  import { CriteriaSelected } from '@app/stores/selectedCriteriaStore.js';

  export let label = undefined;
  export let subject = {};
  export let test = {};

  const { translate } = getContext('app');
  const { outcomeValues } = getContext('Evaluation'); // array of possible outcomes
  $: TRANSLATED = {
    VIEW_IN_REPORT: $translate('PAGES.AUDIT.VIEW_IN_REPORT')
  };

  $: outcomeOptions = $outcomeValues.map((outcomeValue, index) => {
    const title = outcomeValue.title;
    const value = outcomeValue.id;
    return {
      title,
      value,
      selected: index === $outcomeValues.length - 1
    };
  });

  // Find or create the assertion for (subject, test)
  $: _assertion =
    $assertions.find(a => a.test === test && a.subject === subject) 
      || assertions.create({ subject, test });

  // Reactive: compute the rectangle color
  $: circleColor = getCircleColor(_assertion.result.outcome.title);

  // Called when the user changes the outcome in the <Select>
  function handleOutcomeChange(event) {
    const value = event.target.value;
    _assertion.result.outcome = $outcomeValues.find(o => o.id === value);
    _assertion.result.setDate();
    assertions.update(() => $assertions);
  }

  // Called when the user edits the observation text
  function handleResultChange() {
    _assertion.result.setDate();
    assertions.update(() => $assertions);
  }

  function getCircleColor(outcomeTitle) {
    switch (outcomeTitle) {
      case 'Passed':
        // Dark green
        return '#388E3C';
      case 'Failed':
        // Dark red
        return '#B71C1C';
      case 'Cannot tell':
        // Orange
        return '#FB8C00';
      case 'Not present':
        // Gray
        return '#757575';
      default:
        return 'transparent';
    }
  }
</script>
