<!-- src/components/ui/Report/ReportHeaderMultiValue.svelte -->

{#if editing}
<Select
  options={options}
  value={storeToUse[field]}
  on:change={handleChange}
/>
{:else}
  {#if storeToUse[field]}
  <!-- FFG: if mapped titles are available, show title instead of value -->
  {options.find(element => element["value"] == storeToUse[field]).title || storeToUse[field]}
  {:else}
  <span class="no-result">(Not provided)</span>
  {/if}
{/if}

<style>
  .no-result {
    font-weight: normal;
    font-style: italic;
  }
</style>

<script>
  import Select from '@app/components/form/Select.svelte';
  import { getContext } from 'svelte';

  const { scopeStore, summaryStore } = getContext('app');  

  export let field;
  export let editing;
  export let options;
  export let store = "scopeStore";
 
  $: storeToUse = getStore(store); 
  
  function getStore(store) {
    if (store === "scopeStore") {
      return $scopeStore
    }
    if (store === "summaryStore") {
      return $summaryStore
    }
  }

  function handleChange(e) {
    const newVal = e.target.value;
    scopeStore.update(current => {
      current[field] = newVal;
      return current;
    });
  }

</script>
