<!-- @Layout:Base -->
<!-- FFG: remove language selection, since nl is not supported
<div class="Controls default-grid">
  <LanguageSelect locales="{locales}" />
</div>
-->
<div id="site-header" class="site-header">
  <div class="default-grid">
    <div class="tool-header">
      <div class="tool-header-name">
        WAD R<span class="display-phablet">eport </span>T<span class="display-phablet">ool</span>
      </div>
      <div class="tool-header-logo">
        <!-- FFG: set FFG Logo and remove WAI Logo-->
        <a href="https://www.ffg.at/"><img
            alt="FFG"
            src={`${$basepath}/images/ffg.svg`}
            width="100"
            height="42"
          /></a>
        <!--<a href="http://w3.org/WAI/"><img
            alt="Web Accessibility Initiative"
            src={`${$basepath}/images/wai.svg`}
            width="90"
            height="24"
          /></a>-->
      </div>
    </div>
  </div>
</div>

<NavigationBar />

<slot />

{#if !noPagerPage}
<Pager label="{TRANSLATED.STEP}" context="{pagerContext}" />
{/if}

<!-- /@Layout -->

<style>
  /* FFG: overwrite Logo height to add extra height for FFG Logo */
  .tool-header-logo img {
    height: 3em;
  }
  /* FFG: adjust header colors */
  #site-header {
    background-color: white;
    color: black;
  }
  .site-header .tool-header {
    grid-column: 2 / 10;
    width: 100%;
    display: flex;
    align-items: center;
  }
  /*
  .BaseLayout {
    padding: 2em 1em;
  }

  .Controls {
    font-size: 0.8125em;
  }

  @media (min-width: 60em) {
    .BaseLayout {
      padding: 2em 0;
    }
  }
  */
</style>

<script>
  import { getContext } from 'svelte';
  import { useLocation } from 'svelte-navigator';

  import { routes, basepath } from '@app/stores/appStore.js';
  import locales from '@app/locales/index.json';

  import LanguageSelect from '@app/components/ui/LanguageSelect.svelte';
  import NavigationBar from '@app/components/ui/NavigationBar.svelte';
  import Pager from '@app/components/ui/Pager.svelte';

  const { translate } = getContext('app');
  const location = useLocation();

  $: TRANSLATED = {
    STEP: $translate('UI.NAV.STEP', { default: 'step' }),
  };

  $: noPagerPage = false; //$location.pathname === $routes.ACKNOWLEDGEMENTS.path;

  $: pagerContext = Object.keys($routes).map((key) => {
    return $routes[key];
  });  
</script>
